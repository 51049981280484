import React, { Component } from "react";
import axios from "axios";
import swal from "sweetalert";
import fs from "fs";

import PhotoEditor from "../photoEditor/photoEditor";
import TimeSheet from "./timeSheet";


class UserProfile extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isSubmitting:false,
      user: {
                id: "",
                username: "",
                email: "",
                firstName: "",
                lastName: "",
                title: ""
              },
      ut:this.parseJwt(),
      error_message: null,
      avatar: "",
      userId: window.location.href.split('/user/')[1].split('/').slice(0)[0].split('?')[0],
      photoDirty:false,
      detailsDirty:false,
      accountDirty:false
    };

    this.fetch()
  }
  parseJwt() {
    let token = localStorage.getItem("jwtToken");
    var base64Url = token.split(".")[1];
    var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
    var jsonPayload = decodeURIComponent(
      atob(base64)
        .split("")
        .map(function(c) {
          return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join("")
    );

    return JSON.parse(jsonPayload);
  }


  fetch(callback){
    let self=this
    console.log({fetch:"/api/user/"+this.state.userId})
    axios
      .get("/api/users/"+this.state.userId)
      .then(res => {
        if (res.data) {
          let user = res.data
          user.password=''
          self.setState({user},callback)
        }
        else{
          self.setState({user:{
                    id: "",
                    username: "",
                    email: "",
                    firstName: "",
                    lastName: "",
                    title: ""
                  }},callback) 
        }
        // else if (res.data.result === "error") {
          // console.log({user:res});
        // }
      })
      .catch(error => {
        console.log(error);
      });

  }


  componentDidMount() {
  }

  updateField(field,value){
    let user = this.state.user
    user[field]=value
    let newState = {user}
    switch(field){
      case 'image':
        newState.photoDirty=true
        break;
      case 'bio':
      case 'firstName':
      case 'lastName':
      case 'title':
      case 'facebookUrl':
      case 'twitterUrl':
      case 'instagramUrl':
        newState.detailsDirty=true
        break;
      case 'username':
      case 'password':
      case 'jeweler':
      case 'sales':
      case 'admin':
      case 'active':
      case 'isOnWebsite':
      case 'isEJR':
      case 'isWillow':
      case 'isEndless':
        newState.accountDirty=true
        break;
    }
    this.setState(newState)
  }
  updateUserImage(file){
    let self = this
    let user = this.state.user
    if(file){
      let extension = file.type.split('/').slice(-1)[0]
      let fileName = `${user._id}.${extension}`
      
      const blob = new Blob([file], { type: file.type });// WORKS much better (if you know what MIME type you want.

      const formData = new FormData();
      formData.append('file', blob, fileName);
      
      axios.post('/api/users/images', formData, {timeout:1000*60*20})
      .then((upload)=>{
        if(upload && upload.data && upload.data.url){

          // self.setState({image:upload.data.url,photoDirty:false},()=>{
          //   self.fetch()
          // })
          
            axios.put('/api/users/'+user._id,{user:{image:upload.data.url}}).then( (data) => {
              console.log(`User photo updated successfully`);
              console.log(data)

              swal({
                title:"Success!",
                text:'User photo updated successfully',
                type:"success",
                timer:1000
              }).then(value => {
                self.setState({photoDirty:false},()=>{
                  self.fetch()
                })
              });
            }).catch((e) => {
              console.log('Edit failed, Error ',e)
            // self.setState({errors:e.response.data})
          });
        }
        console.log({upload})
      }).catch((err) => {
        console.log({err})
      });	


      console.log({updateUserImage:file,blob,fileName})
      return
    }
    /*
    const blob = new Blob([file], { type: file.type });// WORKS much better (if you know what MIME type you want.
    const formData = new FormData();
    formData.append('file', blob, this.state.product.id + '_'+new Date().getTime() + '.' + extension);
  
    console.log({formData})

      axios.post('/api/products/images', formData, {timeout:1000*60*20})
      .then((upload)=>{
        if(upload && upload.data && upload.data.url){
          let images = self.state.product.images || []
          images.push(upload.data.url)
          self.updateField('images',images,()=>{
            self.update(()=>{
              console.log({updating1:true})
            })
          })
        }
        console.log({upload})
      }).catch((err) => {
        console.log({err})
      });	
      */
  }
  updateUserImage_old(){
    let self=this
    let user = this.state.user
    let {image} = user

    axios.put('/api/users/'+user._id,{user:{image}}).then( (data) => {
        console.log(`User photo updated successfully`);
        console.log(data)

        swal({
          title:"Success!",
          text:'User photo updated successfully',
          type:"success",
          timer:1000
        }).then(value => {
          self.setState({photoDirty:false},()=>{
            self.fetch()
          })
        });
      }).catch((e) => {
        console.log('Edit failed, Error ',e)
      // self.setState({errors:e.response.data})
    });

  }

  updateUserAccount(){
    let self=this
    let user = this.state.user
    let {username,password,jeweler,sales,admin,active,isOnWebsite,isEJR,isWillow,isEndless} = user
    let updateData = {username,password,jeweler,sales,admin,active,isOnWebsite,isEJR,isWillow,isEndless}
    if(updateData && (!updateData.password || (updateData.password && updateData.password.trim().length<1))){
      delete updateData['password']
    }

    axios.put('/api/users/'+user._id,{user:updateData}).then( (data) => {
        console.log(`User account updated successfully`);
        console.log(data)

        swal({
          title:"Success!",
          text:'User account updated successfully',
          type:"success",
          timer:1000
        }).then(value => {

          self.fetch(()=>{
            self.setState({accountDirty:false})
          })
          
        });
      }).catch((e) => {
        console.log('Edit failed, Error ',e)
      // self.setState({errors:e.response.data})
    });
  }

  updateUserDetails(){
    let self=this
    let user = this.state.user
    let {firstName,lastName,title,facebookUrl,twitterUrl,instagramUrl,bio} = user
    let updateData = {firstName,lastName,title,facebookUrl,twitterUrl,instagramUrl,bio}

    axios.put('/api/users/'+user._id,{user:updateData}).then( (data) => {
        console.log(`User details updated successfully`);
        console.log(data)

        swal({
          title:"Success!",
          text:'User details updated successfully',
          type:"success",
          timer:1000
        }).then(value => {
          self.fetch()
        });
      }).catch((e) => {
        console.log('Edit failed, Error ',e)
      // self.setState({errors:e.response.data})
    });

  }

  render(){
    let self=this
    let errors={}
    let touched={}

    let userTypeSelection=null
    let usernameInput = (
      <div className="form-control" style={{fontWeight:'bold',border:'solid 0px white'}}>{this.state.user.username}</div>
    )
    let titleInput = (
      <div className="form-control" style={{fontWeight:'bold',border:'solid 0px white'}}>{this.state.user.title}</div>
    )
    if(this.state.ut.admin){
      userTypeSelection = (
        <React.Fragment key="user_types">
            <div className="col-6 form-check float-sm-right">
              <input className="form-check-input" style={{marginTop:'.8rem',marginLeft:'-.8rem'}} type="checkbox" checked={this.state.user.jeweler||false} onChange={(e)=>{self.updateField('jeweler',e.target.checked)}}/>
              <label className="form-check-label" style={{marginLeft:'.5rem'}}>Jeweler</label><br />
              <input className="form-check-input" style={{marginTop:'.8rem',marginLeft:'-.8rem'}} type="checkbox" checked={this.state.user.sales||false} onChange={(e)=>{self.updateField('sales',e.target.checked)}}/>
              <label className="form-check-label" style={{marginLeft:'.5rem'}}>Sales</label>
              <hr/>
              <input className="form-check-input" style={{marginTop:'.8rem',marginLeft:'-.8rem'}} type="checkbox" checked={this.state.user.isEJR||false} onChange={(e)=>{self.updateField('isEJR',e.target.checked)}}/>
              <label className="form-check-label" style={{marginLeft:'.5rem'}}>EJR</label><br />
              <input className="form-check-input" style={{marginTop:'.8rem',marginLeft:'-.8rem'}} type="checkbox" checked={this.state.user.isWillow||false} onChange={(e)=>{self.updateField('isWillow',e.target.checked)}}/>
              <label className="form-check-label" style={{marginLeft:'.5rem'}}>Willow</label><br />
              <input className="form-check-input" style={{marginTop:'.8rem',marginLeft:'-.8rem'}} type="checkbox" checked={this.state.user.isEndless||false} onChange={(e)=>{self.updateField('isEndless',e.target.checked)}}/>
              <label className="form-check-label" style={{marginLeft:'.5rem'}}>Endless</label><br />
            </div>
            <div className="col-6 form-check float-sm-right">
              <input className="form-check-input" style={{marginTop:'.8rem',marginLeft:'-.8rem'}} type="checkbox" checked={this.state.user.admin||false} onChange={(e)=>{self.updateField('admin',e.target.checked)}}/>
              <label className="form-check-label" style={{marginLeft:'.5rem'}}>Admin</label><br />
              <input className="form-check-input" style={{marginTop:'.8rem',marginLeft:'-.8rem'}} type="checkbox" checked={this.state.user.active||false} onChange={(e)=>{self.updateField('active',e.target.checked)}}/>
              <label className="form-check-label" style={{marginLeft:'.5rem'}}>Active</label><br />
              <input className="form-check-input" style={{marginTop:'.8rem',marginLeft:'-.8rem'}} type="checkbox" checked={this.state.user.isOnWebsite||false} onChange={(e)=>{self.updateField('isOnWebsite',e.target.checked)}}/>
              <label className="form-check-label" style={{marginLeft:'.5rem'}}>Show On Website</label><br />
            </div>
        </React.Fragment>
      )
      usernameInput=(
        <input
          onChange={(e)=>{self.updateField('username',e.target.value)}}
          value={this.state.user.username}
          type="text"
          className="form-control"
          id="username"
          placeholder="UserName"
        />
      )

      titleInput=(
        <input
          onChange={(e)=>{self.updateField('title',e.target.value)}}
          value={this.state.user.title}
          type="text"
          className="form-control"
          id="title"
          placeholder="Title"
        />
      )
    }

    let photoButton=(
      <div className="custom-file">
        <PhotoEditor 
          text="Edit Photo" 
          onImageBlob={(data)=>{
            self.updateUserImage(data.blobFile)
            // self.newPhoto(data.blobFile)
          }}
          // onImageBase64={(data)=>{
          //   self.updateField('image',data.imageB64)
          // // console.log({data})
          // }}
          />
      </div>
    )
    // if(self.state.photoDirty){
    //   photoButton=(
    //     <input type="button" value="Update" className="btn btn-block btn-success" onClick={()=>{
    //       self.updateUserImage()
    //     }} />
    //   )
    // }

    return(
      <div className="content-wrapper">
        <div className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1 className="m-0 text-dark">
                User: {[this.state.user.firstName,this.state.user.lastName].join(' ').trim()}
                </h1>
              </div>{/* /.col */}
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item"><a href="/admin">Admin</a></li>
                  <li className="breadcrumb-item active"><a href="/admin/users">Users</a></li>
                </ol>
              </div>{/* /.col */}
            </div>{/* /.row */}
          </div>{/* /.container-fluid */}
        </div>

        <section className="content">
          <div className="container-fluid">

            <TimeSheet userId={self.state.userId} />


            <div className="row">

                <div className="col-12 col-sm-6 col-md-4 d-flex align-items-stretch">
                  <div className="card card-primary" style={{width: '100%'}}>
                    <div className="card-header">
                      <h3 className="card-title">
                        Photo
                      </h3>
                    </div>
                    <div className="card-body">
                      <div className="row">
                        <div className="col-12 text-center">
                          <img src={this.state.user.image} alt="" className="img-circle img-fluid" style={{maxWidth:'75%'}} />
                        </div>
                      </div>
                    </div>
                    <div className="card-footer">
                      
                      <div className="input-group">
                        {photoButton}
                      </div>

                    </div>
                  </div>
                </div>

                <div className="col-12 col-sm-6 col-md-4 d-flex align-items-stretch">
                  <div className="card card-primary" style={{width: '100%'}}>
                    <div className="card-header">
                      <h3 className="card-title">
                        Details
                      </h3>
                    </div>
                    <div className="card-body">

                      <div className="row">
                        <div className="col-3 text-right">
                          <label htmlFor="firstName">First</label>
                        </div>
                        <div className="col-9 form-group">
                          <input
                            onChange={(e)=>{self.updateField('firstName',e.target.value)}}
                            value={this.state.user.firstName}
                            type="text"
                            className="form-control"
                            id="firstName"
                            placeholder="First Name"
                          />
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-3 text-right">
                          <label htmlFor="lastName">Last</label>
                        </div>
                        <div className="col-9 form-group">
                          <input
                            onChange={(e)=>{self.updateField('lastName',e.target.value)}}
                            value={this.state.user.lastName}
                            type="text"
                            className="form-control"
                            id="lastName"
                            placeholder="Last Name"
                          />
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-3 text-right">
                          <label htmlFor="title">Title</label>
                        </div>
                        <div className="col-9 form-group">
                          {titleInput}
                        </div>
                      </div>
                      
                      <div className="row">
                        <div className="col-3 text-right">
                          <label htmlFor="facebookUrl">Facebook Url</label>
                        </div>
                        <div className="col-9 form-group">
                          <input
                            onChange={(e)=>{self.updateField('facebookUrl',e.target.value)}}
                            value={this.state.user.facebookUrl}
                            type="text"
                            className="form-control"
                            id="facebookUrl"
                            placeholder="Facebook Url"
                          />
                        </div>
                      </div>
                      
                      <div className="row">
                        <div className="col-3 text-right">
                          <label htmlFor="twitterUrl">Twitter Url</label>
                        </div>
                        <div className="col-9 form-group">
                          <input
                            onChange={(e)=>{self.updateField('twitterUrl',e.target.value)}}
                            value={this.state.user.twitterUrl}
                            type="text"
                            className="form-control"
                            id="twitterUrl"
                            placeholder="Twitter Url"
                          />
                        </div>
                      </div>
                      
                      <div className="row">
                        <div className="col-3 text-right">
                          <label htmlFor="instagramUrl">Instagram Url</label>
                        </div>
                        <div className="col-9 form-group">
                          <input
                            onChange={(e)=>{self.updateField('instagramUrl',e.target.value)}}
                            value={this.state.user.instagramUrl}
                            type="text"
                            className="form-control"
                            id="instagramUrl"
                            placeholder="Instagram Url"
                          />
                        </div>
                      </div>
                      
                    </div>
                    <div className="card-footer">
                      
                      <div className="input-group">
                        <input type="button" disabled={!self.state.detailsDirty} value="Update" className="btn btn-block btn-success" onClick={()=>{
                          self.updateUserDetails()
                        }} />
                      </div>

                    </div>
                  </div>
                </div>

                <div className="col-12 col-sm-6 col-md-4 d-flex align-items-stretch">
                  <div className="card card-primary" style={{width: '100%'}}>
                    <div className="card-header">
                      <h3 className="card-title">
                        Account
                      </h3>
                    </div>
                    <div className="card-body">
                      <div className="row">
                        <div className="col-5 text-right">
                          <label htmlFor="username">Username</label>
                        </div>
                        <div className="col-7 form-group">
                          {usernameInput}
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-5 text-right">
                          <label htmlFor="password">Password</label>
                        </div>
                        <div className="col-7 form-group">
                          <input
                            onChange={(e)=>{self.updateField('password',e.target.value)}}
                            value={this.state.user.password || ''}
                            secureTextEntry={true}
                            type="password"
                            className="form-control"
                            id="password"
                            placeholder="********"
                          />
                        </div>
                      </div>

                      <div className="row">
                        {userTypeSelection}
                      </div>
                    </div>
                    <div className="card-footer">
                      
                      <div className="input-group">
                        <input type="button" disabled={!self.state.accountDirty} value="Update" className="btn btn-block btn-success" onClick={()=>{
                          self.updateUserAccount()
                        }} />
                      </div>

                    </div>
                  </div>
                </div>

                
                <div className="col-12 col-sm-6 col-md-4 d-flex align-items-stretch">
                  <div className="card card-primary" style={{width: '100%'}}>
                    <div className="card-header">
                      <h3 className="card-title">
                        Bio
                      </h3>
                    </div>
                    <div className="card-body">
                      <div className="row">
                        <div className="col-12 form-group">
                          <textarea 
                            value={this.state.user.bio}
                            rows={20}
                            style={{width:'100%'}}
                            onChange={(e)=>{
                              self.updateField('bio',e.target.value)
                            }}/>
                        </div>
                      </div>


                      <div className="row">
                        xxx
                      </div>
                    </div>
                    <div className="card-footer">
                      
                      <div className="input-group">
                        <input type="button" disabled={!self.state.detailsDirty} value="Update" className="btn btn-block btn-success" onClick={()=>{
                          self.updateUserDetails()
                        }} />
                      </div>

                    </div>
                  </div>
                </div>


            </div>
          </div>
        </section>
      </div>
    )
  }



}

export default UserProfile;
